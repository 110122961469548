@import "~mono-icons/iconfont/icons.css";
$margin: 16px;
$padding: 8px;
$frame-thickness: 2px;

.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  user-select: none;

  // .top-chrome {
  //   display: flex;
  //   flex-flow: column nowrap;
  //   align-items: flex-end;
  //   position: absolute;
  //   top: 0;
  //   left: 50%;
  //   transform: translate(-50%, 0);
  //   width: calc(100% - (($margin + $frame-thickness) * 2) - 8px);
  //   max-width: 650px;
  //   height: fit-content;
  //   padding-top: 16px;
  //   z-index: 2;

  //   @media all and (display-mode: standalone) and (orientation: portrait) {
  //     padding-top: calc(16px + env(safe-area-inset-top));
  //   }
  // }
}
