.button-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: space-between;
  user-select: none;
  left: 50%;
  transform: translate(-50%, 0);

  &:not(:last-child) {
    margin-right: 16px;
  }
}
