.place-card {
  min-height: 50px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3px;
  padding: 10px 10px 10px;
  transition: height 0.25s cubic-bezier(0.25, 1, 0.5, 1),
    background-color 0.25s ease-in-out;
  overflow: hidden;

  .card-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .index {
    flex: 0 0 auto;
    width: 36px;
    height: 36px;
    margin: 4px 12px 4px 4px;
    border-radius: 50px;
    font-family: lores-12-narrow;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    line-height: 33px;
  }

  .info-wrapper {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 2px;
    width: calc(100% - 8px);
  }

  label {
    display: inline-block;
    text-transform: uppercase;
    font-size: 16px;
    margin: 16px 0 8px 0;
    opacity: 0.4;
  }

  p {
    margin: 0;
  }

  .name {
    margin: 0 0 4px 0;
    line-height: 100%;
    font-family: lores-12;
    font-size: 18px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .address {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--expanded {
    padding: 20px;
    transition: height 0.25s cubic-bezier(0.25, 1, 0.5, 1);

    .name {
      font-size: 22px;
    }

    .location {
      &.clickable {
        .address {
          text-decoration: underline;
        }
      }
      
      .address {
        font-size: 18px;
        opacity: 1;

        &.line1 {
          margin-bottom: 4px;
        }
      }
    }
  }

  .photos {
    display: flex;
    flex-flow: row nowrap;

    .photo,
    .photo-placeholder {
      position: relative;
      max-width: 256px;
      max-height: 256px;
      overflow: hidden;
      border: 2px solid;
      border-radius: 7px;
      text-align: center;
      margin-top: 20px;

      img {
        width: 100%;
        height: 100%;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  .ratingLabelAndValue {
    label {
      display: inline-block;
    }

    .rating {
      display: inline-block;
      margin: 0 0 12px 8px;
    }
  }

  .ratingBar {
    width: 100%;
    padding: 2px;
    border-radius: 8px;
    border: 2px solid;

    .filled {
      height: 8px;
      border-radius: 8px;
    }
  }

  .reviews {
    position: relative;

    marquee {
      width: calc(100% + 8px);
      height: 20px;
      z-index: 1;
    }

    &::before,
    &::after {
      content: " ";
      width: 20px;
      height: 18px;
      display: inline-block;
      position: absolute;
      z-index: 2;
      top: 0;
    }

    &::before {
      left: 0px;
      // background: linear-gradient(
      //   90deg,
      //   rgba(255, 255, 255, 1) 10%,
      //   rgba(255, 255, 255, 0) 100%
      // );
    }

    &::after {
      right: -8px - 1px;
      // background: linear-gradient(
      //   90deg,
      //   rgba(255, 255, 255, 0) 10%,
      //   rgba(255, 255, 255, 1) 100%
      // );
    }
  }
}
