$button-depth: 9px;
$press-depth: 5px;
$border-radius: 7px;

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: $button-depth;
  user-select: none;
  cursor: pointer;
  touch-action: auto;

  &:not(:last-child) {
    margin-right: 16px;
  }

  .chunky-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 20px 14px;
    box-shadow: inset 0px 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    font-family: lores-12-narrow;
    font-size: 24px;
    font-weight: 400;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.25);
    transition: transform 0.1s ease-out;
  }

  .button-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: $button-depth;
    z-index: -1;
    border-radius: $border-radius;
    transition: transform 0.1s ease-out;
  }

  &.is-pressed {
    .chunky-button {
      transform: translateY($press-depth);
    }
  }
}
