.scan-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  mix-blend-mode: soft-light;
  transition: background-color 0.25s ease-in-out;
  pointer-events: none;

  .line {
    width: 100%;
    background: #000;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.3) 48%,
      rgba(0, 0, 0, 0.3) 52%,
      rgba(0, 0, 0, 1) 100%
    );
    mix-blend-mode: soft-light;
    opacity: 0.2;
  }
}
