$padding: 8px;
$frame-thickness: 2px;

.compass-button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  color: #fff;
  text-transform: uppercase;
}

.compass-label {
  color: #95e5cb;
  margin: 0 9px 0 0;
  user-select: none;
}

.compass-state {
  position: relative;
  display: flex;
  margin: 0 6px 0 0;
  color: #95e5cb;
  opacity: 0.5;
  line-height: 16px;
  user-select: none;

  &:last-child {
    margin-right: 0;
  }

  &.is-current-state {
    color: #fff;
    opacity: 1;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -5px;
      transform: translateX(-50%);
      width: 3px;
      height: 3px;
      border-radius: 3px;
      background-color: #95e5cb;
    }
  }
}
