$visible-x-pos: calc(45px - env(safe-area-inset-bottom));

.re-do-search-button {
  position: absolute;
  bottom: 302px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: calc(100% - 38px);
  z-index: 0;
  opacity: 0;
  color: #fff;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &.is-visible {
    transform: translate(-50%, $visible-x-pos);
    opacity: 1;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    z-index: 100;
  }
}
