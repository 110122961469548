.loading-screen {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  z-index: 1;

  p {
    text-transform: uppercase;
  }

  .drops-loader {
    width: 124px;
    animation-duration: 1.25s;
    animation-name: drop-bounce;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  }

  .cup-loader {
    width: 124px;
    animation-duration: 1.25s;
    animation-name: cup-bounce;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  }
}

@keyframes drop-bounce {
  0% {
    transform: translate(10px, 112px) rotate(0deg) scale(30%);
  }
  100% {
    transform: translate(-10px, 12px) rotate(-15deg) scale(100%);
  }
}

@keyframes cup-bounce {
  0% {
    transform: translate(10px, 36px) rotate(15deg);
  }
  100% {
    transform: translate(10px, -12px) rotate(-15deg);
  }
}
