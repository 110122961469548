$margin: 16px;
$padding: 8px;
$frame-thickness: 2px;

.banner-header-wrapper {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: calc(100% - (($margin + $frame-thickness) * 2) - 8px);
  max-width: 697px;
  height: fit-content;
  padding-top: 20px;
  z-index: 100;

  @media all and (display-mode: standalone) and (orientation: portrait) {
    padding-top: calc(16px + env(safe-area-inset-top));
  }
}

.banner-header {
  display: flex;
  position: relative;
  flex-grow: 1;
  height: fit-content;
  justify-content: center;
  padding: $padding 0;
  background-color: #45375e;

  h1 {
    margin: 2px 0 4px 0;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.5));
  }
}
