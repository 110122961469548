.bottom-chrome {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 24px 0 20px;
  z-index: 2;
  // backdrop-filter: blur(10px);
  box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.2),
    inset 0px 4px 0px rgba(255, 255, 255, 0.2);
  transition: background-color 0.1s ease-out;
  user-select: none;

  // &.expanded {
  //   background-color: rgba(0, 0, 0, 0.2);
  //   backdrop-filter: blur(10px);
  // }

  @media all and (display-mode: standalone) and (orientation: portrait) {
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
  }
}
