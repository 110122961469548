@font-face {
  font-family: "pokemon-pixel-font";
  src: url("./fonts/pokemon-pixel-font/pokemon-pixel-font.ttf");
  src: url("./fonts/pokemon-pixel-font/pokemon-pixel-font.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
}

body {
  margin: 0;
  overflow: hidden;
  background-color: #000;
}

* {
  font-family: lores-12, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
