.place-card-empty {
  width: calc(100vw - 68px);
  max-width: 673px;
  height: 50px;
  overflow: hidden;
  position: relative;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3px;
  padding: 10px 10px 10px;
}
