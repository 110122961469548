.heads-up-display {
  position: relative;
  display: none;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 668px;
  margin: -6px 42px 18px 0px;
  padding: 7px 16px 9px 16px;
  border-radius: 7px;
  box-shadow: inset 0px 0px 7px rgba(0 0 0 / 60%),
    0px 2px 1px rgba(255 255 255 / 30%), 0px 0px 0px 1px rgba(0 0 0 / 20%);
  overflow: hidden;
  user-select: none;
  z-index: 2;

  &.is-visible {
    display: flex;
  }

  .places-loader {
    width: 100%;
    margin: 0;
    line-height: 24px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }
}
