$padding: 8px;

.weather-indicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 16px 0 0;

  .weather-label {
    color: #95e5cb;
    text-transform: uppercase;
    margin: 0 9px 0 0;
  }

  .icon-container {
    height: 16px;
  }

  .condition-label {
    margin: 0 4px 0 0;
    color: #fff;
    text-transform: uppercase;
    line-height: 24px;
  }
}
