$frame-thickness: 2px;

.decorative-frame {
  overflow: hidden;
  border: $frame-thickness solid #fff;
  border-radius: 7px;
  box-shadow: inset 0 0 0 2px #000, 0 0 0 3px #000,
    0 4px 0 3px rgba(0, 0, 0, 0.25), inset 0 5px 0px rgba(0, 0, 0, 0.25);

  &--simple {
    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.5),
      0 0 0 $frame-thickness + 1 #000, 0 4px 0 3px rgba(0, 0, 0, 0.25);
  }
}
